import React, { useState, useEffect, useRef, useCallback } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import usePlayList from 'hooks/usePlayList';
import PlayWrapper from 'components/PlayWrapper';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import BeamMonsterLoader from 'components/common/BeamMonsterLoader';
import AdvertisementPlay from 'components/views/AdvertisementPlay';
import useAuth from 'hooks/useAuth';
import Player from '@vimeo/player';
import moment from 'moment';
import _ from 'lodash/fp';
import $ from 'jquery';
import axios from 'axios';

var ADVERTISE_TYPE = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
};

var realPlayList;
var realPlayDuration = 0;
var leftDuration = 0;
var isLoop = false;
var isOnlyOne = false;
var isPreparing = false;
var isUserStop = false;
var isVideoLengthPlay = false;
var containerPlayIndex = 0;
var CHECK_STATUS = true;
var currentPlayer;
var nextPlayer;
var finalPlayer;
var adPlayer;
var currentVideoIndex = 0;
var nextVideoIndex = 0;
var totalElapsedTime = 0;
var lastUpdateTime = 0;
var wakeLock = null;
var CURRENT_VIDEOURL = 'default';
var NEXT_VIDEOURL = 'default';

export default function PlayContainer() {
  const navigator = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [videoType, setVideoType] = useState('4k');
  const [videoMode, setVideoMode] = useState('low');
  const [playInfo, setPlayInfo] = useState({
    playType: 'Ordering',
    playList: [],
    shufflePlayList: [],
    playId: null,
    playStart: true, // 플레이중
    playLoop: false, // 반복
    playMute: true, // 음소거
    playVolume: 100,
    currentPlayTime: null,
    selectedPlay: null,
    playDuration: '300', // 5분 Default
    realPlayList: [],
  });

  const [needRefetch, setNeedRefetch] = useState(true);
  const [advertiseInterval, setAdvertiseInterval] = useState(10);
  const [currentAdvertise, setCurrentAdvertise] = useState();
  const [today, setToday] = useState();
  const [advertises, setAdvertises] = useState([]);
  const [logs, setLogs] = useState({});

  const timeRef = useRef(null);

  const playThrottle = useRef(null);
  const currentPlayerRef = useRef(null);
  const nextPlayerRef = useRef(null);

  const location = useLocation();
  const { videoQuality, videoMethod, isDirect } = qs.parse(location.search);

  const {
    name,
    phone,
    contract,
    estimate,
    email,
    businessName,
    businessAddress,
    handleResetAuthData,
  } = useAuth();

  const loadVimeoPlayerScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById('vimeo-player-script')) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'vimeo-player-script';
      script.src = '/statics/js/player.js';
      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error('Failed to load Vimeo Player script'));
      document.body.appendChild(script);
    });
  };

  const unloadVimeoPlayerScript = () => {
    const script = document.getElementById('vimeo-player-script');
    if (script) {
      document.body.removeChild(script);
    }
  };

  useEffect(() => {
    return () => {
      // 스크립트 언로드
      unloadVimeoPlayerScript();
    };
  }, []);

  const makeVideo = async (videoUrl) => {
    try {
      console.log('131 line : makeVideo bbb ', videoUrl);
      await loadVimeoPlayerScript();
      console.log('133 line : makeVideo aaa ', videoUrl);

      if (!videoUrl) {
        throw new Error('Invalid video URL');
      }

      const iframe = document.createElement('iframe');
      iframe.src = `${videoUrl}&autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&sidedock=0&controls=0&transparent=0&background=1`; // 여기에 Vimeo 비디오 ID를 넣으세요
      iframe.style.width = '100vw';
      iframe.style.height = '100vh';
      iframe.style.border = 'none';
      iframe.setAttribute('allowfullscreen', '');

      // const player = await new Player(iframe);
      console.log('147 line : iframe.src bbb ', iframe.src);
      const player = new window.Vimeo.Player(iframe);
      console.log('149 line : player ', player);

      $('#video-container').append(iframe);

      return player;
    } catch (err) {
      console.log('155 line : makeVideo err: ', err);
      console.log('156 line : makeVideo err.response: ', err?.response);
      console.log('157 line : makeVideo videoUrl: ', videoUrl);
      console.log('158 line : makeVideo CURRENT_VIDEOURL: ', CURRENT_VIDEOURL);
      console.log('159 line : makeVideo NEXT_VIDEOURL: ', NEXT_VIDEOURL);

      Sentry.withScope((scope) => {
        scope.setTag('function', 'makeVideo');
        scope.setExtras({
          errorMessage: err?.message,
          errorStack: err?.stack,
          errorName: err?.name,
          videoUrl,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          name,
          phone,
          contract,
          estimate,
          email,
          businessName,
          businessAddress,
          realPlayList,
          currentVideoIndex,
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        });

        Sentry.captureException(err);
      });

      return null;
    }
  };

  const makeAdVideo = async (videoUrl) => {
    try {
      console.log('187 line : makeAdVideo videoUrl ', videoUrl);
      await loadVimeoPlayerScript();

      if (!videoUrl) {
        throw new Error('Invalid video URL');
      }

      const iframe = document.createElement('iframe');
      iframe.src = `${videoUrl}&autopause=0&muted=1&title=0&byline=0&portrait=0&sidedock=0&controls=0&transparent=0&background=1`; // 여기에 Vimeo 비디오 ID를 넣으세요
      iframe.style.width = '100vw';
      iframe.style.height = '100vh';
      iframe.style.border = 'none';
      iframe.setAttribute('allowfullscreen', '');

      // const player = await new Player(iframe);
      console.log('202 line : window.Vimeo ', window.Vimeo);
      const player = new window.Vimeo.Player(iframe);

      $('#ad-container').append(iframe);

      return player;
    } catch (err) {
      console.log('209 line : makeAdVideo err: ', err);
      console.log('210 line : makeAdVideo err.response: ', err?.response);
      console.log('211 line : makeAdVideo videoUrl: ', videoUrl);
      console.log('212 line : makeAdVideo CURRENT_VIDEOURL: ', CURRENT_VIDEOURL);
      console.log('213 line : makeAdVideo NEXT_VIDEOURL: ', NEXT_VIDEOURL);

      Sentry.withScope((scope) => {
        scope.setTag('function', 'makeAdVideo');
        scope.setExtras({
          errorMessage: err?.message,
          errorStack: err?.stack,
          errorName: err?.name,
          videoUrl,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          name,
          phone,
          contract,
          estimate,
          email,
          businessName,
          businessAddress,
          realPlayList,
          currentVideoIndex,
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        });

        Sentry.captureException(err);
      });

      return null;
    }
  };

  const handleVideoIssue = async () => {
    console.log('240 line : handleVideoIssue!!! realPlayList ', realPlayList);
    console.log('241 line : handleVideoIssue!!! currentVideoIndex ', currentVideoIndex);
    console.log('242 line : handleVideoIssue!!! currentPlayer ', currentPlayer);
    console.log('243 line : handleVideoIssue!!! playInfo ', playInfo);
    if (isUserStop) return;

    if (!realPlayList || realPlayList.length === 0) {
      console.log('247 line : realPlayList is empty');
      return;
    }

    if (currentVideoIndex < 0 || currentVideoIndex >= realPlayList.length) {
      console.log('252 line : currentVideoIndex is out of bounds ', currentVideoIndex);
      return;
    }

    try {
      setIsLoading(true);

      if (currentPlayer) {
        console.log('260 line : currentPlayer off off ', currentPlayer);
        currentPlayer.off('pause');
        currentPlayer.off('error');
        currentPlayer.off('timeupdate');
        currentPlayer.off('bufferend');
        currentPlayer = null;
      }
      console.log('267 line : currentPlayer pass ', currentPlayer);
      isPreparing = false;
      $('#video-container').empty();

      await handleVideo(
        realPlayList[currentVideoIndex].id,
        currentVideoIndex,
        playInfo.playStart,
        true,
      );
    } catch (err) {
      console.log('278 line : handleVideoIssue err: ', err);
      console.log('279 line : handleVideoIssue err?.response: ', err?.response);
      console.log('280 line : handleVideoIssue realPlayList: ', realPlayList);
      console.log('281 line : handleVideoIssue videoId: ', realPlayList[currentVideoIndex]?.id);
      console.log('463 line : handleVideoIssue CURRENT_VIDEOURL', CURRENT_VIDEOURL);
      console.log('464 line : handleVideoIssue NEXT_VIDEOURL', NEXT_VIDEOURL);

      Sentry.withScope((scope) => {
        scope.setTag('function', 'handleVideoIssue');
        scope.setExtras({
          errorMessage: err?.message,
          errorStack: err?.stack,
          errorName: err?.name,
          currentVideoIndex,
          videoId: realPlayList[currentVideoIndex]?.id || null,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          name,
          phone,
          contract,
          estimate,
          email,
          businessName,
          businessAddress,
          realPlayList,
          axiosResponse: err?.response ? err?.response?.data : null,
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        });

        Sentry.captureException(err);
      });

      // 다음 비디오로 넘어가기
      currentVideoIndex = (currentVideoIndex + 1) % realPlayList.length;
      handleVideoIssue();
    }
  };

  var fadeInOut = async (currentPlayer2, nextPlayer2, nextIndex) => {
    let isNextRemoved = false;
    if (!currentPlayer2 || !currentPlayer2?.element) {
      console.log('315 line : currentPlayer is not defined ', currentPlayer2);
      return;
    }

    const localCurrentPlayer = currentPlayer2;
    const localNextPlayer = nextPlayer2;

    try {
      console.log('323 line : fadeInOut called from:', new Error().stack);
      console.log('324 line : fadeInOutfadeInOut currentPlayer1', currentPlayer);
      console.log('325 line : fadeInOutfadeInOut currentPlayer2', localCurrentPlayer);
      console.log('326 line : fadeInOutfadeInOut nextPlayer2', localNextPlayer);
      console.log('327 line : fadeInOutfadeInOut realPlayList ', realPlayList);
      console.log('328 line : fadeInOutfadeInOut nextIndex ', nextIndex);

      await Promise.all([
        new Promise((resolve) => {
          $(localCurrentPlayer?.element)
            .removeClass('active')
            .animate({ opacity: 0 }, 5000, function () {
              console.log('335 line : $(this) ', $(this));
              localCurrentPlayer.off('timeupdate');
              localCurrentPlayer.off('pause');
              localCurrentPlayer.off('error');
              localCurrentPlayer.off('bufferend');
              $(this).remove(); // 현재 요소를 제거합니다.
              resolve();
            });
        }),
        new Promise((resolve) => {
          $(localNextPlayer?.element).css('opacity', 0);
          $(localNextPlayer?.element)
            .addClass('active')
            .animate({ opacity: 1 }, 3000, function () {
              resolve();
            });
        }),
      ]);

      currentPlayer = localNextPlayer;
      const localPlayer = currentPlayer;
      const localNext1 = nextPlayer;
      CURRENT_VIDEOURL = NEXT_VIDEOURL;
      NEXT_VIDEOURL = null;
      console.log('358 line : fadeInOut CURRENT_VIDEOURL ', CURRENT_VIDEOURL);
      console.log('359 line : fadeInOut NEXT_VIDEOURL ', NEXT_VIDEOURL);

      localPlayer.on('pause', handleVideoIssue);
      localPlayer.on('error', handleVideoIssue);
      localPlayer.on('timeupdate', async function (data) {
        if (!localPlayer || !localPlayer?.element) {
          console.log('362 line : currentPlayer is not defined ', localPlayer);
          throw new Error('Player 생성 실패');
        }

        if (!isNextRemoved) {
          isNextRemoved = true;
          localNext1.off('bufferend');
          nextPlayer = null;
        }

        const currentTime = (await localPlayer?.getCurrentTime()) || 0;
        const currentTimeStamp = Date.now();
        const elapsedTimeSinceLastUpdate =
          ((currentTimeStamp -
            (lastUpdateTime === 0 ? currentTimeStamp : lastUpdateTime)) /
            1000) %
          60; // 초 단위로 변환
        lastUpdateTime = currentTimeStamp;

        totalElapsedTime += elapsedTimeSinceLastUpdate;

        console.log('383 line : currentPlayer?.paused ', await localPlayer?.getPaused());
        console.log('384 line : isPreparing ', isPreparing);
        console.log('385 line : timeupdate ', data);
        console.log('386 line : leftDuration ', leftDuration);
        console.log('387 line : totalElapsedTime ', totalElapsedTime);
        console.log(
          '389 line :  !isPreparing && totalElapsedTime >= leftDuration ',
          !isPreparing && totalElapsedTime >= leftDuration,
        );
        console.log('392 line : check currentPlayer ', localPlayer);
        console.log('393 line : check currentPlayer2 ', currentPlayer2);
        console.log('394 line : check nextPlayer2 ', localNextPlayer);

        window?.opener?.postMessage(
          { currentPlayTime: currentTime },
          process.env.REACT_APP_ORIGIN,
        );

        if (isOnlyOne || isLoop) {
          totalElapsedTime = 0;
          if (!isUserStop) {
            console.log('404 line : fadeInOut isLoop || isOnlyOne play !!! ');
            localPlayer?.setVolume(0);
            await localPlayer?.play();
          }
        } else if (!isPreparing && totalElapsedTime >= leftDuration) {
          console.log('409 line : next!!!!! realPlayList bbb ', realPlayList);
          isPreparing = true;
          console.log('411 line : next!!!!! currentVideoIndex aaa ', currentVideoIndex);
          console.log('412 line : fadeInOutfadeInOut nextVideoIndex bbb ', nextVideoIndex);
          nextVideoIndex = (nextIndex + 1) % realPlayList.length;
          console.log('414 line : fadeInOutfadeInOut nextVideoIndex aaa ', nextVideoIndex);
          currentVideoIndex = nextVideoIndex;
          const nextIndex2 = currentVideoIndex;
          console.log('417 line : fadeInOut realPlayList bbb ', realPlayList);
          console.log('418 line : fadeInOut realPlayList[nextVideoIndex]?.id bbb ', realPlayList[nextVideoIndex]?.id);
          const nextRes = await api.getContent(
            realPlayList[nextVideoIndex]?.id,
            true,
          );
          console.log('423 line : fadeInOut nextRes aaa ', nextRes);
          console.log('424 line : fadeInOut nextRes?.data?.videoUrl aaa ', nextRes?.data?.videoUrl);
          NEXT_VIDEOURL = nextRes?.data?.videoUrl;
          console.log('425 line : fadeInOut NEXT_VIDEOURL ', NEXT_VIDEOURL);
          const player = await makeVideo(nextRes?.data?.videoUrl);
          console.log('426 line : fadeInOut player aaa ', player);
          nextPlayer = player;
          const localNext2 = nextPlayer;
          if (!localNext2) throw new Error('다음 Player 생성 실패');
          console.log('430 line : here123 ', player);
          console.log('431 line : nextPlayer123 ', localNext2);
          console.log('432 line : nextPlayer2 456 ', localNextPlayer);
          localNext2.on('bufferend', async function () {
            console.log('434 line : bufferend!!!!! ');
            localNext2?.setVolume(0);
            localNext2?.play();
            lastUpdateTime = 0;
            totalElapsedTime = 0;
            console.log(
              '440 line : fadeInOutfadeInOut nextVideoIndex gogo ',
              nextVideoIndex,
            );
            console.log(
              '444 line : fadeInOutfadeInOut currentVideoIndex gogo ',
              currentVideoIndex,
            );
            await fadeInOut(localPlayer, localNext2, currentVideoIndex);
            console.log('448 line : fadeInOut finish isPreparing bbb ', isPreparing);
            isPreparing = false;
            console.log('450 line : fadeInOut finish isPreparing aaa ', isPreparing);
            console.log('451 line : fadeInOutfadeInOut nextIndex gogo ', nextIndex2);
            window?.opener?.postMessage(
              {
                currentPlayTime: 0,
                playId: realPlayList[nextIndex2].id,
              },
              process.env.REACT_APP_ORIGIN,
            );
          });
        } else {
          if (!isUserStop) {
            console.log('462 line : fadeInOut wow play!!!! ');
            console.log('463 line : fadeInOut wow CURRENT_VIDEOURL', CURRENT_VIDEOURL);
            console.log('464 line : fadeInOut wow NEXT_VIDEOURL', NEXT_VIDEOURL);
            localPlayer?.setVolume(0);
            await localPlayer?.play();
          }
        }
      });

      const isPaused = await localPlayer?.getPaused();
      console.log('470 line : currentPlayer look status paused ', isPaused);
      if (isPaused) {
        console.log('472 line : currentPlayer is paused, resuming...');
        if (!isUserStop) {
          console.log('474 line : fadeInOut start play!!!! ');
          localPlayer?.setVolume(0);
          await localPlayer?.play();
        }
      }
    } catch (err) {
      console.log('480 line : fadeInOut err ', err);
      console.log('481 line : fadeInOut err?.response ', err?.response);
      console.log('482 line : currentVideoIndex:', currentVideoIndex);
      console.log('483 line : 스택 트레이스:', err?.stack);
      console.log('484 line : CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
      console.log('485 line : NEXT_VIDEOURL:', NEXT_VIDEOURL);

      Sentry.withScope((scope) => {
        scope.setTag('function', 'fadeInOut');
        scope.setExtras({
          errorMessage: err?.message,
          errorStack: err?.stack,
          errorName: err?.name,
          currentVideoIndex,
          videoId: realPlayList[currentVideoIndex]?.id || null,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          name,
          phone,
          contract,
          estimate,
          email,
          businessName,
          businessAddress,
          realPlayList,
          axiosResponse: err?.response ? err?.response.data : null,
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        });

        Sentry.captureException(err);
      });

      // 다음 비디오로 넘어갑니다.
      currentVideoIndex = (currentVideoIndex + 1) % realPlayList.length;
      handleVideoIssue();
    }
  };

  const handleVideo = async (videoId, currentIndex, playStart, isIssue) => {
    try {
      console.log('516 line : handleVideo currentIndex ', currentIndex);
      console.log('517 line : handleVideo isIssue ', isIssue);
      console.log('518 line : handleVideo videoId bbb ', videoId);
      const res = await api.getContent(videoId, true);
      console.log('520 line : handleVideo res aaa ', res);
      console.log('521 line : handleVideo res?.data?.videoUrl aaa ', res?.data?.videoUrl);
      CURRENT_VIDEOURL = res?.data?.videoUrl;
      console.log('522 line : CURRENT_VIDEOURL ', CURRENT_VIDEOURL);
      const player = await makeVideo(res?.data?.videoUrl);
      console.log('523 line : handleVideo player aaa ', player);
      if (!player) throw new Error('현재 Player 생성 실패');
      console.log('525 line : currentPlayer currentPlayer bbb ', currentPlayer);
      const localCurrentPlayer = player;
      currentPlayer = localCurrentPlayer;
      console.log('528 line : currentPlayer currentPlayer aaa ', currentPlayer);

      localCurrentPlayer.on('bufferend', function () {
        console.log('531 line : currentPlayer bufferend ');
        if (playStart) {
          console.log('533 line : currentPlayer bufferend playStart');
          localCurrentPlayer?.setVolume(0);
          localCurrentPlayer?.play();
        }

        console.log('538 line : currentPlayer bufferend active');
        $(localCurrentPlayer.element).addClass('active');
        setIsLoading(false);
      });

      if (isIssue) {
        console.log('544 line : currentPlayer isIssue playStart ', playStart);
        if (playStart) {
          console.log('546 line : currentPlayer isIssue play ');
          localCurrentPlayer?.setVolume(0);
          localCurrentPlayer?.play();
        }

        console.log('551 line : currentPlayer isIssue active');
        $(localCurrentPlayer?.element).addClass('active');
        setIsLoading(false);
      }

      console.log('556 line : currentPlayer just go');

      localCurrentPlayer.on('timeupdate', async function (data) {
        const currentTime = (await localCurrentPlayer?.getCurrentTime()) || 0;
        const currentTimeStamp = Date.now();
        const elapsedTimeSinceLastUpdate =
          ((currentTimeStamp -
            (lastUpdateTime === 0 ? currentTimeStamp : lastUpdateTime)) /
            1000) %
          60; // 초 단위로 변환
        lastUpdateTime = currentTimeStamp;

        totalElapsedTime += elapsedTimeSinceLastUpdate;
        console.log('569 line : isPreparing ', isPreparing);
        console.log('570 line : timeupdate ', data);
        console.log('571 line : leftDuration ', leftDuration);
        console.log('572 line : totalElapsedTime ', totalElapsedTime);
        console.log(
          '574 line : !isPreparing && totalElapsedTime >= leftDuration ',
          !isPreparing && totalElapsedTime >= leftDuration,
        );
        window?.opener?.postMessage(
          { currentPlayTime: currentTime },
          process.env.REACT_APP_ORIGIN,
        );
        if (isOnlyOne || isLoop) {
          totalElapsedTime = 0;
          if (!isUserStop) {
            console.log('584 line : handleVideo isLoop || isOnlyOne play !!! ');
            localCurrentPlayer?.setVolume(0);
            await localCurrentPlayer?.play();
          }
        } else if (!isPreparing && totalElapsedTime >= leftDuration) {
          console.log('589 line : change!!!! ');
          isPreparing = true;
          currentVideoIndex = (currentIndex + 1) % realPlayList.length;
          const nextVideoIndex = (currentIndex + 1) % realPlayList.length;
          console.log('593 line : handleVideo change realPlayList bbb ', realPlayList);
          console.log('594 line : handleVideo change nextVideoIndex bbb ', nextVideoIndex);
          console.log('595 line : handleVideo change realPlayList[nextVideoIndex]?.id bbb ', realPlayList[nextVideoIndex]?.id);
          const res = await api.getContent(
            realPlayList[nextVideoIndex]?.id,
            true,
          );
          console.log('600 line : handleVideo change res aaa ', res);
          console.log('601 line : handleVideo change res?.data?.videoUrl aaa ', res?.data?.videoUrl);
          NEXT_VIDEOURL = res?.data?.videoUrl;
          console.log('602 line : handleVideo change NEXT_VIDEOURL ', NEXT_VIDEOURL);
          const player = await makeVideo(res?.data?.videoUrl);
          console.log('603 line : handleVideo change player aaa ', player);
          const localNextPlayer = player;
          nextPlayer = localNextPlayer;
          if (!localNextPlayer) throw new Error('다음 Player 생성 실패');
          localNextPlayer.on('bufferend', async function () {
            // 비디오를 자동 재생
            localNextPlayer?.setVolume(0);
            localNextPlayer?.play();
            lastUpdateTime = 0;
            totalElapsedTime = 0;
            console.log(
              '614 line : handleVideo currentVideoIndex gogo ',
              currentVideoIndex,
            );
            console.log('617 line : handleVideo nextVideoIndex bbb ', nextVideoIndex);
            await fadeInOut(
              localCurrentPlayer,
              localNextPlayer,
              nextVideoIndex,
            );
            console.log('623 line : fadeInOut finish isPreparing bbb ', isPreparing);
            isPreparing = false;
            console.log('625 line : fadeInOut finish isPreparing bbb ', isPreparing);
            console.log('626 line : handleVideo nextVideoIndex aaa ', nextVideoIndex);
            window?.opener?.postMessage(
              {
                currentPlayTime: 0,
                playId: realPlayList[nextVideoIndex]?.id,
              },
              process.env.REACT_APP_ORIGIN,
            );
          });
        } else {
          if (!isUserStop) {
            console.log('637 line : handleVideo play !!! ');
            localCurrentPlayer?.setVolume(0);
            await localCurrentPlayer?.play();
          }
        }
      });
      localCurrentPlayer.on('pause', handleVideoIssue);
      localCurrentPlayer.on('error', handleVideoIssue);
    } catch (err) {
      console.log('646 line : handleVideo err: ', err);
      console.log('647 line : handleVideo err?.response: ', err?.response);
      console.log('648 line : handleVideo videoId: ', videoId);
      console.log('649 line : handleVideo CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
      console.log('650 line : handleVideo NEXT_VIDEOURL:', NEXT_VIDEOURL);

      Sentry.withScope((scope) => {
        scope.setTag('function', 'handleVideo');
        scope.setExtras({
          errorMessage: err?.message,
          errorStack: err?.stack,
          errorName: err?.name,
          videoId,
          currentIndex,
          isIssue,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          name,
          phone,
          contract,
          estimate,
          email,
          businessName,
          businessAddress,
          realPlayList,
          currentVideoIndex,
          axiosResponse: err?.response ? err?.response.data : null,
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        });

        Sentry.captureException(err);
      });

      // 다음 비디오로 넘어가기
      currentVideoIndex = (currentVideoIndex + 1) % realPlayList.length;
      handleVideoIssue();
    }
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (isFullScreen) {
          setIsFullScreen(false);
        }
      }
    },
    [isFullScreen, setIsFullScreen],
  );

  const exitHandler = useCallback(() => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, setIsFullScreen]);

  const handlePlayCurrentVideo = async (id, index, playStart) => {
    try {
      console.log('704 line : handlePlayCurrentVideo id ', id);
      console.log('705 line : handlePlayCurrentVideo index ', index);
      setIsLoading(true);
      if (currentPlayer) {
        currentPlayer.off('pause');
        currentPlayer.off('error');
        currentPlayer.off('timeupdate');
        currentPlayer.off('bufferend');
        currentPlayer = null;
      }
      isPreparing = false;
      $('#video-container').empty();
      console.log(
        '717 line : handlePlayCurrentVideo currentVideoIndex bbb ',
        currentVideoIndex,
      );
      currentVideoIndex = index;
      console.log(
        '722 line : handlePlayCurrentVideo currentVideoIndex aaa ',
        currentVideoIndex,
      );
      await handleVideo(id, index, playStart, false);
    } catch (err) {
      console.log('727 line : handlePlayCurrentVideo err ', err);
      console.log('728 line : handlePlayCurrentVideo id ', id);
      console.log('729 line : handlePlayCurrentVideo index ', index);
      console.log('730 line : handlePlayCurrentVideo CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
      console.log('731 line : handlePlayCurrentVideo NEXT_VIDEOURL:', NEXT_VIDEOURL);

      Sentry.captureException(err, {
        tags: { function: 'handlePlayCurrentVideo' },
        extra: {
          id,
          index,
          playStart,
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          CURRENT_VIDEOURL,
          NEXT_VIDEOURL,
        },
      });

      // 다음 비디오로 넘어가기
      currentVideoIndex = (currentVideoIndex + 1) % realPlayList.length;
      handleVideoIssue();
    }
  };

  const handleParentData = async (data) => {
    setPlayInfo(data);
    console.log('749 line : data ', data);
    if (data?.playNext || data?.playPrev) {
      setIsLoading(true);
      // currentPlayerRef?.current?.off('timeupdate');
      if (CHECK_STATUS) {
        // currentPlayerRef?.current?.off('pause');
        // currentPlayerRef?.current?.off('error');
      }
    }

    if (playThrottle.current) {
      clearTimeout(playThrottle.current);
    }

    playThrottle.current = setTimeout(async () => {
      // handleChangePlayData(data);
      try {
        if (data.hasOwnProperty('playList')) {
          realPlayList = data.playList;
          if (realPlayList.length === 1) {
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (!realPlayList || realPlayList.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window?.close();
            return;
          }
        }

        if (data.hasOwnProperty('playId')) {
          if (!data.playList || data?.playList?.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            return;
          }

          let playIndex;
          data.playList.some((el, index) => {
            if (el.id === data.playId) {
              playIndex = index;
            }
            return el.id === data.playId;
          });

          if (playIndex === 0) {
            realPlayList = data.playList;
          } else if (playIndex === data.playList.length - 1) {
            const last = data.playList[playIndex];
            const restArr = data.playList.filter((el) => el.id !== last.id);
            realPlayList = [last, ...restArr];
          } else {
            const pastArray = data.playList.slice(0, playIndex);
            const newArray = data.playList.slice(
              playIndex,
              data.playList.length,
            );
            realPlayList = [...newArray, ...pastArray];
          }

          if (realPlayList.length === 1) {
            isOnlyOne = true;
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (data?.playNext) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
            isPreparing = false;
          }

          if (data?.playPrev) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
            isPreparing = false;
          }

          if (data?.removed) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
          }
        }

        if (data.hasOwnProperty('currentPlayTime')) {
          currentPlayer?.setCurrentTime(data.currentPlayTime);
        }

        if (data.hasOwnProperty('playVolume')) {
          currentPlayer?.setVolume(data.playVolume / 100);
        }

        if (data.hasOwnProperty('playMute')) {
          if (data.playMute) {
            currentPlayer?.setVolume(0);
          } else {
            currentPlayer?.setVolume(data.playVolume / 100);
          }
        }

        if (data.hasOwnProperty('playLoop')) {
          isLoop = data.playLoop;
        }

        if (data.hasOwnProperty('playStart')) {
          try {
            if (currentPlayer) {
              if (data.playStart) {
                isUserStop = false;
                currentPlayer?.setVolume(0);
                currentPlayer?.play();
              } else {
                isUserStop = true;
                currentPlayer?.pause();
              }
            }
          } catch (err) {
            console.log('891 line : playStart ', err);
          }
        }

        if (data.hasOwnProperty('playDuration')) {
          realPlayDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          leftDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          isVideoLengthPlay = data.playDuration === 'auto';
        }

        if (data.hasOwnProperty('isInit')) {
          if (data?.isInit) {
            await handleVideo(
              realPlayList[currentVideoIndex].id,
              currentVideoIndex,
              data.playStart,
              false,
            );
          }
        }
      } catch (err) {
        console.log('913 line : handleParentData err ', err);
        console.log('914 line : handleParentData err?.response ', err?.response);
        console.log('915 line : handleParentData CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
        console.log('916 line : handleParentData NEXT_VIDEOURL:', NEXT_VIDEOURL);

        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );

        Sentry.withScope((scope) => {
          scope.setTag('function', 'handleParentData - 데이터 전달');
          scope.setExtras({
            errorMessage: err?.message,
            errorStack: err?.stack,
            errorName: err?.name,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            name,
            phone,
            contract,
            estimate,
            email,
            businessName,
            businessAddress,
            CURRENT_VIDEOURL,
            NEXT_VIDEOURL,
          });

          Sentry.captureException(err);
        });
        window?.close();
      }
      if (data?.playNext || data?.playPrev) {
        setIsLoading(false);
      }
    }, 2000);
  };

  const receiveMessage = async (e) => {
    // 도메인 체크 X
    // if (e.origin !== process.env.REACT_APP_ORIGIN) return;
    if (e.data.mode === 'play') {
      await handleParentData(e.data);
    }
  };

  useEffect(() => {
    if (!isInit) return;
    window.addEventListener('message', receiveMessage, false);
    window?.opener?.postMessage(
      { windowStatus: 'ready' },
      process.env.REACT_APP_ORIGIN,
    );
    return () => {
      window?.removeEventListener('message', receiveMessage, false);
    };
  }, [isInit]);

  window.onbeforeunload = function (e) {
    window?.opener?.postMessage(
      { windowStatus: 'refresh' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  const alertUser = () => {
    // window?.opener?.postMessage(
    //   { windowStatus: 'close' },
    //   process.env.REACT_APP_ORIGIN,
    // );
    // window.close();
  };

  const handleOnlineVideo = async () => {
    try {
    } catch (err) {
      console.log('handleOnlineVideo err ', err);
      console.log('handleOnlineVideo err response ', err?.response);
    }
  };

  const handleOfflineVideo = (e) => {
    console.log('991 line : handleOfflineVideo offline! ');
    console.log('992 line : handleOfflineVideo offline! CURRENT_VIDEOURL ', CURRENT_VIDEOURL);
    console.log('993 line : handleOfflineVideo offline! NEXT_VIDEOURL ', NEXT_VIDEOURL);

    alert(
      '네트워크가 오프라인상태가 되었습니다. 네트워크를 확인 후 다시 실행해주세요!',
    );

    Sentry.withScope((scope) => {
      scope.setTag('video', 'handleOfflineVideo');

      scope.setContext('offlineContext', {
        e: e,
        connection: {
          connection: window?.navigator?.connection,
          timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        },
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
        CURRENT_VIDEOURL,
        NEXT_VIDEOURL,
      });

      Sentry.captureException(e);
    });
  };

  async function handleChangeNetwork(e) {
    // Handle change of connection type here.
    console.error(
      '1022 line : CHANGE - Navigator.connection ',
      window?.navigator?.connection,
    );
    console.log('1025 line : CHANGE - Navigator.connection eee ', e);
    console.log('1026 line : CHANGE - Navigator.connection eee 111 ', e?.currentTarget);
    console.log('1027 line : CHANGE - Navigator.connection eee 222 ', e?.srcElement);
    console.log('1028 line : CHANGE - Navigator.connection eee 333 ', e?.target);
    console.log('1029 line : CHANGE - Navigator.connection CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
    console.log('1030 line : CHANGE - Navigator.connection NEXT_VIDEOURL:', NEXT_VIDEOURL);

    if (window?.navigator?.onLine && !currentPlayer.playing) {
      currentPlayer?.setVolume(0);
      currentPlayer?.play()?.catch(handleVideoIssue);
    }

    Sentry.withScope((scope) => {
      scope.setTag('function', 'handleChangeNetwork');
      scope.setExtras({
        e: e,
        errorNetwork: {
          currentTarget: e?.currentTarget,
          srcElement: e?.srcElement,
          target: e?.target,
          timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        },
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
        CURRENT_VIDEOURL,
        NEXT_VIDEOURL,
      });

      Sentry.captureException(e);
    });
  }

  useEffect(() => {
    // window?.navigator?.connection?.addEventListener(
    //   'change',
    //   handleChangeNetwork,
    // );
    window.addEventListener('online', handleVideoIssue);
    window.addEventListener('offline', handleOfflineVideo);

    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('webkitfullscreenchange', exitHandler, false);
    window.addEventListener('mozfullscreenchange', exitHandler, false);
    window.addEventListener('fullscreenchange', exitHandler, false);
    window.addEventListener('MSFullscreenChange', exitHandler, false);
    window.addEventListener('keydown', handleUserKeyPress, false);
    window.addEventListener('keypress', handleUserKeyPress, false);

    const handleVisibilityChange = async () => {
      console.log('1075 line : handleVisibilityChange!!!!');
      if (document.visibilityState === 'visible' && !isUserStop) {
        wakeLock = await window?.navigator?.wakeLock?.request('screen');
        currentPlayer?.setVolume(0);
        console.log('1079 line : handleVisibilityChange!!!! play');
        currentPlayer?.play()?.catch(handleVideoIssue);
      } else if (document.visibilityState === 'hidden' && !isUserStop) {
        // currentPlayerRef.current?.pause();
        wakeLock = null;
        currentPlayer?.setVolume(0);
        console.log('1085 line : handleVisibilityChange!!!! hidden');
        currentPlayer?.play()?.catch(handleVideoIssue);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // window?.navigator?.connection?.removeEventListener(
      //   'change',
      //   handleChangeNetwork,
      // );
      window.removeEventListener('online', handleVideoIssue);
      window.removeEventListener('offline', handleOfflineVideo);

      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('webkitfullscreenchange', exitHandler, false);
      window.removeEventListener('mozfullscreenchange', exitHandler, false);
      window.removeEventListener('fullscreenchange', exitHandler, false);
      window.removeEventListener('MSFullscreenChange', exitHandler, false);
      window.removeEventListener('keydown', handleUserKeyPress, false);
      window.addEventListener('keypress', handleUserKeyPress, false);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => { }),
          api.getMyContract().catch(() => { }),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
              window?.opener?.postMessage(
                { windowStatus: 'close' },
                process.env.REACT_APP_ORIGIN,
              );
              window.close();
            }
          } else {
            alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window.close();
          }
        } else {
          alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
          window?.opener?.postMessage(
            { windowStatus: 'close' },
            process.env.REACT_APP_ORIGIN,
          );
          window.close();
        }
      } catch (err) {
        alert('인증 실패');
        console.log('1160 line : estimateContract err ', err);
        console.log('1161 line : estimateContract err?.response ', err?.response);
        console.log('1162 line : estimateContract CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
        console.log('1163 line : estimateContract NEXT_VIDEOURL:', NEXT_VIDEOURL);

        Sentry.withScope((scope) => {
          scope.setTag('estimateContract', 'estimateContractError');

          scope.setContext('offlineContext', {
            config: err?.config,
            response: err?.response,
            timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
            name,
            phone,
            estimate,
            contract,
            email,
            businessName,
            businessAddress,
            CURRENT_VIDEOURL,
            NEXT_VIDEOURL,
          });

          Sentry.captureException(err);
        });
        alert(
          '권한이 확인되지 않습니다. 로그인 후 다시 한 번 확인해주세요 : )',
        );
        navigator('/');
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window.close();
      }
    })();
  }, [name, phone, estimate, contract, email, businessName, businessAddress]);

  // 시간
  useEffect(() => {
    if (process.env.REACT_APP_TEMP_BLOCK === 'YES') return;
    if (!needRefetch) return;
    (async () => {
      try {
        const [time, advertises, info] = await Promise.all([
          api.getCurrentTime(),
          api.getAdvertisesToday(),
          api.getAdvertiseInfo(),
        ]);
        const date = moment(time?.data).format('YYMMDD');
        const logs = await api.getAdvertiseLogs(date);
        console.log('1207 line : advertises ', advertises);
        console.log('1208 line : logs ', logs);
        console.log('1209 line : info ', info);
        setAdvertiseInterval(info?.data?.advertiseInterval);
        setToday(moment(time?.data).format('YYMMDD'));
        setAdvertises(advertises?.data);
        setLogs(logs?.data);
        setNeedRefetch(false);
      } catch (err) {
        console.log('1216 line : 광고 데이터 로드 err:', err);
        console.log('1217 line : 광고 데이터 로드 err?.response:', err?.response);
        console.log('1218 line : 광고 데이터 로드 CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
        console.log('1219 line : 광고 데이터 로드 NEXT_VIDEOURL:', NEXT_VIDEOURL);

        Sentry.withScope((scope) => {
          scope.setTag('function', 'useEffect - 광고 데이터 로드');
          scope.setExtras({
            errorMessage: err?.message,
            errorStack: err?.stack,
            errorName: err?.name,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            name,
            phone,
            contract,
            estimate,
            email,
            businessName,
            businessAddress,
            CURRENT_VIDEOURL,
            NEXT_VIDEOURL,
          });

          Sentry.captureException(err);
        });

        // alert(
        //   '권한이 확인되지 않습니다. 로그인 후 다시 한 번 확인해주세요 : )',
        // );
        // navigator('/');
        // window?.opener?.postMessage(
        //   { windowStatus: 'close' },
        //   process.env.REACT_APP_ORIGIN,
        // );
        // window.close();
      }
    })();
  }, [
    needRefetch,
    name,
    phone,
    contract,
    estimate,
    email,
    businessName,
    businessAddress,
  ]);

  const adVideoEnded = async () => {
    console.log('1261 line : adVideoEnded exec!!!!!! ');
    $(adPlayer.element)
      .removeClass('active')
      .fadeOut(1000, function () {
        $(this).remove(); // 현재 요소를 제거합니다.
        adPlayer.off('ended');
        adPlayer = null;
      });
  };

  // 광고 송출
  useEffect(() => {
    if (process.env.REACT_APP_TEMP_BLOCK === 'YES') return;
    if (timeRef.current) {
      clearInterval(timeRef.current);
    }

    timeRef.current = setInterval(() => {
      (async () => {
        try {
          let isLessThan30M = false;
          const res = await api.getCurrentTime();
          const hour = moment(res?.data).format('HH');
          const minute = Number(moment(res?.data).format('mm')) || 0;
          if (today !== moment(res?.data).format('YYMMDD')) {
            setNeedRefetch(true);
            return;
          }
          console.log('1289 line : timeRef res ', res);
          let type = ADVERTISE_TYPE.A;
          if (advertiseInterval === 10) {
            if (minute < 10) {
              type = ADVERTISE_TYPE.A;
            } else if (minute < 20) {
              type = ADVERTISE_TYPE.B;
            } else if (minute < 30) {
              type = ADVERTISE_TYPE.C;
            } else if (minute < 40) {
              type = ADVERTISE_TYPE.D;
            } else if (minute < 50) {
              type = ADVERTISE_TYPE.E;
            } else {
              type = ADVERTISE_TYPE.F;
            }
          } else {
            if (minute < 5) {
              type = ADVERTISE_TYPE.A;
              isLessThan30M = true;
            } else if (minute < 10) {
              type = ADVERTISE_TYPE.B;
              isLessThan30M = true;
            } else if (minute < 15) {
              type = ADVERTISE_TYPE.C;
              isLessThan30M = true;
            } else if (minute < 20) {
              type = ADVERTISE_TYPE.D;
              isLessThan30M = true;
            } else if (minute < 25) {
              type = ADVERTISE_TYPE.E;
              isLessThan30M = true;
            } else if (minute < 30) {
              type = ADVERTISE_TYPE.F;
              isLessThan30M = true;
            } else if (minute < 35) {
              type = ADVERTISE_TYPE.A;
            } else if (minute < 40) {
              type = ADVERTISE_TYPE.B;
            } else if (minute < 45) {
              type = ADVERTISE_TYPE.C;
            } else if (minute < 50) {
              type = ADVERTISE_TYPE.D;
            } else if (minute < 55) {
              type = ADVERTISE_TYPE.E;
            } else {
              type = ADVERTISE_TYPE.F;
            }
          }

          console.log('timeRef advertises gogo ', advertises);
          console.log('timeRef logs gogo ', logs);
          console.log('timeRef hour gogo ', hour);
          console.log('timeRef type gogo ', type);
          console.log('timeRef advertiseInterval gogo ', advertiseInterval);
          console.log('timeRef isLessThan30M gogo ', isLessThan30M);

          if (
            !logs ||
            !logs[hour] ||
            !logs[hour][type] ||
            logs[hour][type]?.length === 0
          ) {
            return;
          }

          const playedCount = logs[hour][type]?.filter(
            (isPlayed) => isPlayed === true,
          )?.length;

          console.log('playedCount, gogogogo!!!! ', playedCount);

          if (advertiseInterval === 10) {
            if (playedCount >= 1 || !advertises[type]) return;
          } else {
            if (playedCount >= 2 || !advertises[type]) return;
            if (isLessThan30M && playedCount >= 1) return;
          }

          const player = await makeAdVideo(advertises[type]);
          adPlayer = player;

          console.log('adPlayer, gogogogo!!!! ', adPlayer);

          adPlayer.on('bufferend', function () {
            console.log('adPlayer ', adPlayer);
            setTimeout(() => {
              $(adPlayer.element).addClass('active');
              console.log('adVideoEnded ', adVideoEnded);
              adPlayer.on('ended', adVideoEnded);
              adPlayer?.setVolume(0);
              adPlayer.play();
            }, 1000);
          });

          setCurrentAdvertise(type);

          if (advertiseInterval === 10) {
            await api.postAdvertiseLogs(
              type,
              moment(res?.data).format('YYMMDDHH'),
            );
            const temp = _.cloneDeep(logs);
            temp[hour][type].unshift(true);
            temp[hour][type].pop();
            setLogs(temp);
            console.log('timeRef, postAdvertiseLogs111 endend!!!! ', temp);
          } else {
            if (!isLessThan30M && playedCount === 0) {
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              const temp = _.cloneDeep(logs);
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              setLogs(temp);
              console.log('timeRef, postAdvertiseLogs222 endend!!!! ', temp);
            } else {
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              const temp = _.cloneDeep(logs);
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              setLogs(temp);
              console.log('timeRef, postAdvertiseLogs333 endend!!!! ', temp);
            }
          }
        } catch (err) {
          console.log('광고 송출 에러:', err);

          Sentry.withScope((scope) => {
            scope.setTag('function', 'useEffect - 광고 송출');
            scope.setExtras({
              errorMessage: err?.message,
              errorStack: err?.stack,
              errorName: err?.name,
              timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
              name,
              phone,
              contract,
              estimate,
              email,
              businessName,
              businessAddress,
              currentAdvertise,
              logs,
              advertiseInterval,
              advertises,
              CURRENT_VIDEOURL,
              NEXT_VIDEOURL,
            });

            Sentry.captureException(err);
          });

          adVideoEnded();
        }
      })();
    }, 1000 * 60);

    return () => clearInterval(timeRef.current);
  }, [
    today,
    advertiseInterval,
    logs,
    advertises,
    currentAdvertise,
    businessAddress,
    businessName,
    name,
    phone,
    contract,
    estimate,
    email,
  ]);

  // Wake Lock API 사용
  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        console.log(
          '1477 line : window?.navigator?.wakeLock ',
          window?.navigator?.wakeLock,
        );
        if (document.visibilityState === 'visible') {
          wakeLock = await window?.navigator?.wakeLock?.request('screen');
          console.log('1482 line : Wake Lock acquired: ', wakeLock);
        } else {
          console.log('1484 line : Page is not visible. Wake Lock request aborted.');
          wakeLock = null;
        }
        console.log('1487 line : Wake Lock!!! ', wakeLock);
      } catch (err) {
        console.log('1489 line : Wake Lock 요청 에러: ', err);
        console.log('1490 line : Wake Lock 요청 CURRENT_VIDEOURL:', CURRENT_VIDEOURL);
        console.log('1491 line : Wake Lock 요청 NEXT_VIDEOURL:', NEXT_VIDEOURL);

        Sentry.withScope((scope) => {
          scope.setTag('function', 'useEffect - 광고 송출');
          scope.setExtras({
            errorMessage: err?.message,
            errorStack: err?.stack,
            errorName: err?.name,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            name,
            phone,
            contract,
            estimate,
            email,
            businessName,
            businessAddress,
            CURRENT_VIDEOURL,
            NEXT_VIDEOURL,
          });

          Sentry.captureException(err);
        });
      }
    };

    requestWakeLock();

    return () => {
      if (wakeLock) {
        wakeLock.release();
        wakeLock = null;
      }
    };
  }, []);

  const handleClick = async (e, index) => {
    e.preventDefault();
    setIsFullScreen(true);
    window.document.documentElement.requestFullscreen();
  };

  if (!isInit) return <></>;

  return (
    <>
      {isLoading && <BeamMonsterLoader />}
      <AdvertisementPlay />
      <PlayWrapper
        isFullScreen={isFullScreen}
        playList={playInfo?.playList}
        onClick={handleClick}
      />
    </>
  );
}
